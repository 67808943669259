import cache from '@/core/cache'

export default {
  success (message, title = 'Erfolgreich', obj = {}) {
    // TODO: use and test JSON.deepAssign(...)
    return window.vue.$swal.fire(Object.assign({}, {
      title:
      '<img class="sweet-alert2__icon success" src="/images/swal-icons/swal-success.svg">' +
      '<span class="sweet-alert2__title">' +
        title +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    }, obj))
  },

  error (message, title = 'Fehler') {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon danger" src="/images/swal-icons/swal-danger.svg">' +
      '<span class="sweet-alert2__title">' +
        title +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    })
  },

  info (message, title = 'Information') {
    // this.message('info', 'Information', message)
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon info" src="/images/swal-icons/swal-info.svg">' +
      '<span class="sweet-alert2__title">' +
        title +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    })
  },

  warning (message) {
    // this.message('warning', 'Warnung', message)
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">', // +
      // '<span class="sweet-alert2__title">' +
      //   title +
      // '</span>',
      html: message,
      icon: false,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn no-margin'
      }
    })
  },

  unsavedData (save, cancel) {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon new-change-confirmation" src="/images/swal-icons/swal-mission.svg">' +
      '<span class="sweet-alert2__title">' +
        'Ungespeicherte Daten' +
      '</span>',
      text: 'Es gibt noch Daten, die nicht gespeichert wurden. Möchten Sie diese Speichern?',
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Speichern',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header are-you-sure',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        save()
      } else {
        cancel()
      }
    })
  },

  onInNewChangeConfirmation (title, callback) {
    return window.vue.$swal.fire({
      title: `<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg"><span class="sweet-alert2__title">${title}</span>`,
      text: 'Diese Änderung wirkt sich auf alle zukünftigen Vorgänge aus. Vergangene Vorgänge werden nicht berücksichtigt.',
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Bestätigen',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal new-change-confirmation',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        callback()
      }
    })
  },

  successRemember (message, title = 'Erfolgreich', cb, obj = {}) {
    if (cache.getStored('remember_convert.' + title, () => 'false') === 'true') {
      return cb()
    }
    this.success(message, title, obj).then(() => {
      cb()
    })
  },

  areYouSure (message, callback, title = 'Achtung') {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">' +
      '<span class="sweet-alert2__title">' +
        title +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Ja',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        callback()
      }
    })
  },

  areYouSureDealWon (message, callback) {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon" src="/images/swal-icons/swal-create-order.svg">' +
      '<span class="sweet-alert2__title">' +
        'Auftrag erstellen' +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Auftrag erstellen',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2 create-order-alert',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        callback()
      }
    })
  },

  areYouSureCheckbox (message, inputPlaceholder, callback) {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">' +
      '<span class="sweet-alert2__title">' +
        'Achtung' +
      '</span>',
      html: message,
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Ja',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      input: 'checkbox',
      inputValue: 1,
      inputPlaceholder,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result)
      }
    })
  },

  deleteCustomField (fieldName, callback) {
    // const swalWithVue = window.vue.$swal.mixin({
    // })

    return window.vue.$swal.fire({
      title:
        '<img class="sweet-alert2__icon warning" src="/images/swal-icons/swal-warning.svg">' +
        '<span class="sweet-alert2__title">' +
          'Feld entfernen?' +
        '</span>',
      html: 'Möchten Sie das Feld <strong>' + fieldName + '</strong> wirklich entfernen? Dadurch werden alle in diesem Feld gespeicherten Daten unwiederruflich gelöscht!',
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Lieber nicht',
      cancelButtonColor: '#FFFFFF',
      confirmButtonText: 'Entfernen',
      confirmButtonColor: '#FF0000',
      reverseButtons: true,
      input: 'checkbox',
      inputValue: 0,
      inputPlaceholder: 'Ja, ich bin mir sicher',
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2 delete-custom-field',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn disabled',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      },
      buttonsStyling: false,
      willOpen: () => {
        window.vue.$swal.getInput().addEventListener('change', () => {
          const confirmButton = document.querySelector('.sweet-alert2__confirm-btn')
          if (window.vue.$swal.getInput().checked) {
            confirmButton.classList.remove('disabled')
          } else {
            confirmButton.classList.add('disabled')
          }
        })
      },
      preConfirm: () => {
        if (!window.vue.$swal.getInput().checked) {
          return false
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result)
      }
    })
  },

  delete (message, callback) {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon danger" src="/images/swal-icons/swal-danger.svg">' +
      '<span class="sweet-alert2__title">' +
        'Löschen' +
      '</span>',
      html: message,
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Löschen',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      customClass: {
        container: 'sweet-alert2',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions',
        confirmButton: 'btn sweet-alert2__confirm-btn danger',
        cancelButton: 'btn sweet-alert2__cancel-btn'
      }
    }).then((result) => {
      if (result.value) {
        callback()
      }
    })
  },

  messageHtml (type, title, message, obj = {}) {
    // Define the default customClass object
    const defaultCustomClass = {
      container: 'sweet-alert2',
      popup: 'sweet-alert2__modal',
      header: 'sweet-alert2__header',
      title: 'sweet-alert2__title-bar',
      closeButton: 'sweet-alert2__close-btn',
      content: 'sweet-alert2__content',
      actions: 'sweet-alert2__actions',
      confirmButton: 'btn sweet-alert2__confirm-btn',
      // Use the value from obj.showCancelButton to determine if a cancelButton class should be set
      cancelButton: obj.showCancelButton ? 'btn sweet-alert2__cancel-btn' : null
    }

    // Use object spreading to merge the passed customClass with the default customClass
    const mergedCustomClass = {
      ...defaultCustomClass,
      ...obj.customClass
    }

    return window.vue.$swal.fire({
      icon: type,
      title: title,
      html: message,
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Okay',
      confirmButtonColor: false,
      cancelButtonText: 'Abbrechen',
      reverseButtons: true,
      buttonsStyling: false,
      showClass: {
        popup: ''
      },
      hideClass: {
        popup: ''
      },
      ...obj,
      customClass: mergedCustomClass
    })
  },

  subscribeSuccess (callback = () => {}) {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon" src="/images/swal-icons/party-icon.svg">',
      text: 'Prima, Ihr Tarif wurde erfolgreich gebucht. Sie können jetzt durchstarten 🚀',
      icon: false,
      showCancelButton: false,
      showCloseButton: false,
      showDenyButton: false,
      cancelButtonText: false,
      confirmButtonText: 'Alles klar, dann starte ich mal',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      animation: false,
      customClass: {
        container: 'sweet-alert2 subscribe success',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions button-group',
        cancelButton: 'btn sweet-alert2__cancel-btn',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        denyButton: 'btn sweet-alert2__deny-btn'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result)
      }
    })
  },

  noAvailableLicense () {
    const html = '<div class="flex flex-col items-center justify-center"><h5 class="text-lg font-bold text-gray-800">Nicht ausreichend Lizenzen vorhanden</h5><p class="text-gray-700">Es sind leider keine Lizenzen mehr vorhanden.</p></div>'

    return window.vue.$swal.fire({
      title: '<img class="sweet-alert2__icon" src="/images/swal-icons/px-warning.svg">',
      html,
      icon: false,
      showCancelButton: true,
      showCloseButton: true,
      showDenyButton: false,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Lizenz hinzufügen',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      animation: false,
      customClass: {
        container: 'sweet-alert2 subscribe warning md',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar text-gray-800',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions button-group',
        cancelButton: 'btn sweet-alert2__cancel-btn',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        denyButton: 'btn sweet-alert2__deny-btn'
      }
    })
  },

  subscribeWarningLimit (workspaceOwner = true) {
    const html = `<div class="text-sm">
    <p class="text-gray-700">Sie können in diesem Modul leider keine neuen Vorgänge mehr anlegen, da Sie das <strong>Limit im Free-Tarif</strong> erreicht haben 😔</p>
    <p class="text-gray-700">${workspaceOwner ? '<strong>Upgraden</strong> Sie jetzt Ihren <strong>Tarif</strong>, damit Sie unbegrenzt neue Vorgänge anlegen können!' : 'Bitte <strong>kontaktieren</strong> Sie den Administrator Ihres Workspaces für ein <strong>Tarif-Upgrade</strong>.'}</p>
  </div>`

    const confirmButtonText = workspaceOwner ? 'Tarif upgraden' : 'OK'

    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon" src="/images/swal-icons/px-warning.svg"><strong>Limit erreicht</strong>',
      html,
      icon: false,
      showCancelButton: workspaceOwner,
      showCloseButton: true,
      showDenyButton: false,
      cancelButtonText: 'Abbrechen',
      confirmButtonText,
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      animation: false,
      customClass: {
        container: 'sweet-alert2 subscribe warning md',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar text-gray-800',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions button-group',
        cancelButton: workspaceOwner ? 'btn sweet-alert2__cancel-btn' : undefined,
        confirmButton: 'btn sweet-alert2__confirm-btn',
        denyButton: 'btn sweet-alert2__deny-btn'
      }
    })
  },

  subscribeUpgrade (workspaceOwner = true) {
    const html = `<div class="text-sm">
    <p class="text-gray-700">Ihr Tarif reicht für dieses Feature leider nicht aus 😔</p>
    <p class="text-gray-700">${workspaceOwner ? '<strong>Upgraden</strong> Sie jetzt auf <strong>Pro oder Enterprise</strong>, damit Sie dieses Feature nutzen können!' : 'Bitte <strong>kontaktieren</strong> Sie den Administrator Ihres Workspaces für ein <strong>Tarif-Upgrade</strong>.'}<p>
  </div>`

    const confirmButtonText = workspaceOwner ? 'Tarif upgraden' : 'OK'

    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon" src="/images/swal-icons/px-warning.svg"><strong>Upgrade notwendig</strong>',
      html,
      icon: false,
      showCancelButton: workspaceOwner,
      showCloseButton: true,
      showDenyButton: false,
      cancelButtonText: 'Abbrechen',
      confirmButtonText,
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      animation: false,
      customClass: {
        container: 'sweet-alert2 subscribe warning md',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar text-gray-800',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions button-group',
        cancelButton: workspaceOwner ? 'btn sweet-alert2__cancel-btn' : undefined,
        confirmButton: 'btn sweet-alert2__confirm-btn',
        denyButton: 'btn sweet-alert2__deny-btn'
      }
    })
  },

  subscribeWarning (additionalText = '') {
    const html = `<div class="text-sm">
      <p class="text-gray-700">Für diese Aktion reicht Ihr aktueller Tarif leider nicht aus 😔  ${additionalText} Sie können aber in wenigen Schritten einen neuen Tarif buchen!</p>
    </div>`
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon" src="/images/swal-icons/px-warning.svg">' +
      `<a href="${ window.vue.$hostnameMain }workflow#workflowPrice" target="_blank">Erfahren Sie hier mehr über alle Tarife</a>`,
      // text: 'Für diese Aktion reicht Ihr aktueller Tarif leider nicht aus 😔 ' + additionalText + 'Sie können aber in wenigen Schritten einen neuen Tarif buchen!',
      html,
      icon: false,
      showCancelButton: false,
      showCloseButton: false,
      showDenyButton: false,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'Tarif buchen',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      animation: false,
      customClass: {
        container: 'sweet-alert2 subscribe warning w-link',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar text-gray-800',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions button-group',
        cancelButton: 'btn sweet-alert2__cancel-btn',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        denyButton: 'btn sweet-alert2__deny-btn'
      }
    })
  },

  subscribeWarningBasic (additionalText = '', callback = () => {}) {
    return window.vue.$swal.fire({
      title:
      '<img class="sweet-alert2__icon" src="/images/swal-icons/swal-warning.svg">' +
      `<a href="${ window.vue.$hostnameMain }workflow#workflowPrice" target="_blank">Erfahren Sie hier mehr über alle Tarife</a>`,
      text: 'Für diese Aktion reicht Ihr aktueller Tarif leider nicht aus 😔 ' + additionalText + 'Bitte kontaktieren Sie den Admin dieses Workspaces für ein Tarif-Upgrade.',
      icon: false,
      showCancelButton: false,
      showCloseButton: false,
      showDenyButton: false,
      cancelButtonText: 'Abbrechen',
      confirmButtonText: 'OK',
      confirmButtonColor: false,
      reverseButtons: true,
      buttonsStyling: false,
      animation: false,
      customClass: {
        container: 'sweet-alert2 subscribe warning warning--basic',
        popup: 'sweet-alert2__modal',
        header: 'sweet-alert2__header',
        title: 'sweet-alert2__title-bar',
        closeButton: 'sweet-alert2__close-btn',
        content: 'sweet-alert2__content',
        actions: 'sweet-alert2__actions button-group',
        cancelButton: 'btn sweet-alert2__cancel-btn',
        confirmButton: 'btn sweet-alert2__confirm-btn',
        denyButton: 'btn sweet-alert2__deny-btn'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result)
      }
    })
  }
}
